import { marked } from "marked";
import { baseUrl } from "marked-base-url";
import {markedHighlight} from "marked-highlight";
import hljs from "highlight.js";
import { Marked } from "marked";

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    nuxtApp.useMarked = () => {
        const instance = new Marked();
        instance.setOptions({
            headerIds: false,
            mangle: false,
        });
        instance.use(markedHighlight({
            langPrefix: 'hljs language-',
            highlight(code, lang) {
                const language = hljs.getLanguage(lang) ? lang : 'plaintext';
                return hljs.highlight(code, { language }).value;
            }
        }));
        instance.use(baseUrl(config.public.mediaBaseUrl));
        return instance;
    }
});