import { default as _91slug_93WIsUaVTqlRMeta } from "/opt/app/pages/blog/[slug].vue?macro=true";
import { default as indexEohrYP9OICMeta } from "/opt/app/pages/blog/index.vue?macro=true";
import { default as index1JQZ1qn7X7Meta } from "/opt/app/pages/index.vue?macro=true";
export default [
  {
    name: _91slug_93WIsUaVTqlRMeta?.name ?? "blog-slug",
    path: _91slug_93WIsUaVTqlRMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93WIsUaVTqlRMeta || {},
    alias: _91slug_93WIsUaVTqlRMeta?.alias || [],
    redirect: _91slug_93WIsUaVTqlRMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexEohrYP9OICMeta?.name ?? "blog",
    path: indexEohrYP9OICMeta?.path ?? "/blog",
    meta: indexEohrYP9OICMeta || {},
    alias: indexEohrYP9OICMeta?.alias || [],
    redirect: indexEohrYP9OICMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index1JQZ1qn7X7Meta?.name ?? "index",
    path: index1JQZ1qn7X7Meta?.path ?? "/",
    meta: index1JQZ1qn7X7Meta || {},
    alias: index1JQZ1qn7X7Meta?.alias || [],
    redirect: index1JQZ1qn7X7Meta?.redirect || undefined,
    component: () => import("/opt/app/pages/index.vue").then(m => m.default || m)
  }
]