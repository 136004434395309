import axios from "axios";

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const baseURL = process.server ? config.public.ssrApiBaseUrl : config.public.apiBaseUrl;
    const axiosServices = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer 96b054741f06765061ae944d3df34a2ab08b6aff814c4944c8300279a62fa2d12bd36ea9254e63f30be883f85edf2bd6eb1d7c2f505a10766ddb9d5196c1cce2acdf2039433d8004274f906c74ae75c86970789cf98faff59de39108374521da902c6e9685056efb804405bc7ecb8c5b0b1cad3bd8ba755430b6e3ea7215ba12',
        },
        timeout: 10000,
        withCredentials: false,
    });

    axiosServices.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response?.data?.statusCode === 401) {
                navigateTo('/');
            }

            console.log(error);

            return Promise.reject((error.response && error.response.data) || "Wrong Services");
        }
    );

    nuxtApp.axios = axiosServices;
});